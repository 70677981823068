// MARK: React
import React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import { NextPage } from "next";
import { useRouter } from "next/router";

// MARK: Resources
import Strings from "../../resources/Strings";

const ErrorPage: NextPage = () => {

	const router = useRouter();
	const strings = Strings({ acceptLanguage: router.locale });

	if (typeof window !== "undefined") {
		setTimeout(() => {
			location.href = "/";
		}, 4000);
	}

	return (
		<div className={classes.container}>
			<div className={classes.overlay}>
				<div className={classes.grid}>
					<div className={classes.content}>
						<h1>{strings.error.title}</h1>
						<p>{strings.error.redirect}</p>
					</div>
				</div>
			</div>
			<div className={classes.transition} />
		</div>
	);
};

export default ErrorPage;
